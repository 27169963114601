$grey-1: #707070;
$grey-2: #3c4043;
$grey-3: #888;
$blue-1: #1c73e7;
$blue-2: #66afe9;
$base-color: #fff;
$font-family: 'Montserrat', sans-serif;

$lg-breakpoint: 1200px;
$md-breakpoint: 1024px;
$sm-breakpoint: 768px;
